<script lang="ts" setup>
import AppIconRow from "~/components/app/AppIconRow.vue";

interface Props {
  label: string;
  text: string;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="rounded-xl bg-white p-4 text-[1.1rem] font-extralight leading-snug">
    <AppIconRow :count="5" icon="mdi:star" color="#ed8d5a" width="1.3rem" class="ml-[-3px]" />
    <div class="my-1 text-neutral-700">
      <small> "<span v-html="$t(props.text)" />" </small>
    </div>
    <div>
      <small class="text-neutral-500">{{ $t(props.label) }}</small>
    </div>
  </div>
</template>
